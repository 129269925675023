import { env } from '../env'
const BASE_URL = env.REACT_APP_BACKEND_URL;

// const BASE_URL = window.location.href;

export const AUTHENTICATION_API = {
  LOGIN: `${BASE_URL}/authenticate`,
  LOGOUT: `${BASE_URL}/logout`,
  RESET_PASSWORD: `${BASE_URL}/newpass`,
  FORGOT_PASSWORD: `${BASE_URL}/forgotpassword`,
  CHECK_FIRST_LOGIN: (email) => `${BASE_URL}/checkistemp?email=${email}`,
  GET_QR: `${BASE_URL}/gauth/qr`,
  VALIDATE_CODE: `${BASE_URL}/gauth/validate`,
};

export const DASHBOARD_API = `${BASE_URL}`;

export const MAP_API = {
  GET_VERTICALS_BY_COUNTRY: (countryCode) =>
    `${BASE_URL}/map/country/verticals?country=${countryCode}`,
};

export const USER_API = {
  USERS: `${BASE_URL}/users`,
  USER: (userId) => `${BASE_URL}/users/get?id=${userId}`,
  CREATE_USER: `${BASE_URL}/users/create`,
  UPDATE_USER: `${BASE_URL}/users/update`,
  DELETE_USER: `${BASE_URL}/users/delete`,
  RESET_USER_PASSWORD: `${BASE_URL}/users/resetPassword`,
  USER_PROFILE: `${BASE_URL}/profile`,
  UDPATE_PROFILE_EMAIL: `${BASE_URL}/profile/update/email`,
  UDPATE_PROFILE_PASSWORD: `${BASE_URL}/profile/update/password`,
  UDPATE_PROFILE_NAME: `${BASE_URL}/profile/update/name`,
};

export const CUSTOMER_API = {
  CUSTOMERS: `${BASE_URL}/customers`,
  SUBSCRIPTIONS: (customerId) =>
    `${BASE_URL}/customers/subscribers?id=${customerId}`,
  CUSTOMERS_SUBSCRIPTIONS: `${BASE_URL}/customerstree`,
  SUBSCRIPTION: (subId) => `${BASE_URL}/subscribers?id=${subId}`,
  SUBSCRIPTION_RESEND_PASSWORD: `${BASE_URL}/subscribers/passresend`,
  SUBSCRIPTION_UNLINK: `${BASE_URL}/subscribers/unlink`,
};

export const CONTENT_API = {
  CHANNELS: `${BASE_URL}/contents/channels`,
  CHANNEL: (channelId) => `${BASE_URL}/contents/channel?id=${channelId}`,
  CHANNELS_LIST: `${BASE_URL}/contents/channels/list`,
  CREATE_CHANNELS_CATEGORY: `${BASE_URL}/contents/channels/categories/create`,
  UPDATE_CHANNELS_CATEGORY: `${BASE_URL}/contents/channels/categories/update`,
  DELETE_CHANNELS_CATEGORY: `${BASE_URL}/contents/channels/categories/delete`,
  RESTORE_CHANNELS_CATEGORY: `${BASE_URL}/contents/channels/categories/restore`,
  UPDATE_RANK_CATEGORY: `${BASE_URL}/contents/channel/category/rank`,
  UPDATE_RANK_GLOBAL: `${BASE_URL}/contents/channel/rank`,
  ATTACH_CONTENT_TO_CAT: `${BASE_URL}/contents/channels/categories/attach`,
  DETACH_CONTENT_FROM_CAT: `${BASE_URL}/contents/channels/categories/deattach`,
  EPG_CHANNELS: `${BASE_URL}/epgs/get/channels`,
  ATTACH_EPG_CHANNEL: `${BASE_URL}/epgs/attach`,
  DETACH_EPG_CHANNEL: `${BASE_URL}/epgs/deattach`,
  MOVIES: `${BASE_URL}/contents/movies`,
  MOVIE: (movieId) => `${BASE_URL}/contents/movie?id=${movieId}`,
  SERIES: `${BASE_URL}/contents/series`,
  SERIE: (serieId) => `${BASE_URL}/contents/serie?id=${serieId}`,
  PACKAGES: `${BASE_URL}/contents/packages`,
  OFFER_CONTENTS: (offerId) => `${BASE_URL}/contents/offer?planId=${offerId}`,
  ADDON_CONTENTS: (addonId) => `${BASE_URL}/contents/addon?planId=${addonId}`,
  NEW_CONTENT_NOT_ASSIGNED: `${BASE_URL}/contents/notassigned`,
  NEW_CONTENT_ASSIGN: `${BASE_URL}/contents/assign`,
  ATTACH_CONTENT: `${BASE_URL}/contents/attach`,
  DETACH_CONTENT: `${BASE_URL}/contents/deattach`,
  REFRESH_FEED_CONTENTS: `${BASE_URL}/contents/feedcontents`,
  FEED_HISTORY: `${BASE_URL}/contents/history`,
  CONTENTS: (type) => `${BASE_URL}/contents/type${type ? "?type=" + type : ""}`,
};


export const ROWS_API = {
  ROWS: `${BASE_URL}/rows`,
  CREATE_ROW: `${BASE_URL}/rows/create`,
  UPDATE_ROW: `${BASE_URL}/rows/update`,
  DELETE_ROW: `${BASE_URL}/rows/delete`,
  REORDER_ROWS: `${BASE_URL}/rows/reorder`,
  ATTACH_VODS: `${BASE_URL}/rows/attachvods`,
  DETACH_VODS: `${BASE_URL}/rows/deattachvods`,
  UPDATE_VOD_RANK: `${BASE_URL}/rows/vodrank`,
};

export const VERTICALS_API = {
  VERTICALS: `${BASE_URL}/verticals/vods`,
  GET_ALL_VERTICALS: `${BASE_URL}/verticals/getall`,
  CREATE_VERTICAL: `${BASE_URL}/verticals/create`,
  UPDATE_VERTICAL: `${BASE_URL}/verticals/update`,
  DELETE_VERTICAL: `${BASE_URL}/verticals/delete`,
  GET_ONE_VERTICAL: (verticalId) =>
    `${BASE_URL}/verticals/get?id=${verticalId}`,
  ATTACH_VODS: `${BASE_URL}/verticals/attachvods`,
  DETACH_VODS: `${BASE_URL}/verticals/deattachvods`,
  SYNC_VODS: `${BASE_URL}/verticals/vods/sync`,
  SET_DEFAULT: `${BASE_URL}/verticals/setdefault`,
  UPDATE_VOD_RANK: `${BASE_URL}/verticals/vodrank`,
};

export const MESSAGE_API = {
  MESSAGES: `${BASE_URL}/messages`,
  MESSAGE: (messageId) => `${BASE_URL}/messages/get?id=${messageId}`,
  CREATE_MESSAGE: `${BASE_URL}/messages/create`,
  UPDATE_MESSAGE: `${BASE_URL}/messages/update`,
  DELETE_MESSAGE: `${BASE_URL}/messages/delete`,
};

export const PREFERENCES_API = {
  PREFERRED_CONTENTS: `${BASE_URL}/preferred/all`,
  PREFERRED_CONTENT: (id) => `${BASE_URL}/preferred/get?elementId=${id}`,
  CREATE_PREFERRED_CONTENT: `${BASE_URL}/preferred/create`,
  UPDATE_PREFERRED_CONTENT: `${BASE_URL}/preferred/update`,
  REORDER_PREFERRED_CONTENTS: `${BASE_URL}/preferred/reorder`,
  DELETE_PREFERRED_CONTENT: `${BASE_URL}/preferred/delete`,
  RESTORE_PREFERRED_CONTENT: `${BASE_URL}/preferred/restore`,
  SET_DEFAULT_PREFERRED_CONTENT: `${BASE_URL}/preferred/setdefault`,
  STORE_LOCATIONS: `${BASE_URL}/storelocations/emirates/all`,
  UPDATE_EMIRATE: `${BASE_URL}/storelocations/emirates/update`,
  DELETE_EMIRATE: `${BASE_URL}/storelocations/emirates/delete`,
  RESTORE_EMIRATE: `${BASE_URL}/storelocations/emirates/restore`,
  CREATE_AREA: `${BASE_URL}/storelocations/areas/create`,
  UPDATE_AREA: `${BASE_URL}/storelocations/areas/update`,
  DELETE_AREA: `${BASE_URL}/storelocations/areas/delete`,
  RESTORE_AREA: `${BASE_URL}/storelocations/areas/restore`,
  // STORE_LOCATION: (id) => `${BASE_URL}/storelocations/get?elementId=${id}`,
  // CREATE_STORE_LOCATION: `${BASE_URL}/storelocations/create`,
  // UPDATE_STORE_LOCATION: `${BASE_URL}/storelocations/update`,
  // DELETE_STORE_LOCATION: `${BASE_URL}/storelocations/delete`,
};

export const HELP_API = {
  HELP: `${BASE_URL}/help`,
  SAVE_CONTACT: `${BASE_URL}/help/contact/update`,
  SAVE_TERMS: `${BASE_URL}/help/terms/update`,
  CREATE_FAQ_SECTION: `${BASE_URL}/help/faq/section/create`,
  UPDATE_FAQ_SECTION: `${BASE_URL}/help/faq/section/updateComposed`,
  REORDER_FAQ_SECTION: `${BASE_URL}/help/faq/section/reorder`,
  REORDER_FAQ_SUBSECTION: `${BASE_URL}/help/faq/subsection/reorder`,
  DELETE_FAQ_SECTION: `${BASE_URL}/help/faq/section/delete`,
  DELETE_FAQ_SUBSECTION: `${BASE_URL}/help/faq/subsection/delete`,
  EDIT_FAQ_SECTION: `${BASE_URL}/help/faq/section/update`,
  EDIT_FAQ_SUBSECTION: `${BASE_URL}/help/faq/subsection/update`,
  RESTORE_FAQ_SECTION: `${BASE_URL}/help/faq/section/restore`,
  RESTORE_FAQ_SUBSECTION: `${BASE_URL}/help/faq/subsection/restore`,
};

// export const HEALTH_CHECK = {
//   STATUS: `${BASE_URL}/build/status`,
// }
