import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { t } from "i18next";

import classes from "./Subscriptions.module.scss";
import CustomSelectInput from "../../../components/ui/inputs/customSelectInput/CustomSelectInput";
import Circle from "../../../components/ui/circle/Circle";
import SearchSortView from "../../../components/ui/searchSort/searchSortView/SearchSortView";
import SubscriptionCard from "../../../components/contentCards/subscriptionCard/SubscriptionCard";
import customerManager from "../../../backend/managers/CustomerManager";
import SubscriptionModal from "../../../components/modals/subscriptionModal/SubscriptionModal";
import subscriptionManager from "../../../backend/managers/SubscriptionManager";
import Loader from "../../../components/ui/loader/Loader";
import Toast from "../../../components/toast/Toast";

function Subscriptions() {
  const { customerId } = useParams();

  let customers = customerManager.getCustomers();

  const OPTIONS = subscriptionManager.getOptions();
  const headerSubscriptionData =
    subscriptionManager.getHeaderSubscriptionData();

  const [subscriptions, setSubscriptions] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedSubscription, setSelectedSubscription] = useState();
  const [selectedFilter, setSelectedFilter] = useState(OPTIONS && OPTIONS[0]);
  const [isEditing, setIsEditing] = useState(false);
  const [isDeviveModalOpen, setIsDeviveModalOpen] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [messageText, setMessageText] = useState("");
  const [searchInputValue, setSearchInputValue] = useState("");

  const permissions = JSON.parse(localStorage.getItem("userData"))?.permissions;
  const canEdit = permissions?.devices?.edit;

  const navigate = useNavigate();

  const getSubscriptionsApiHandler = async () => {
    const result = await subscriptionManager.getSubscriptionsApi(customerId);
    if (result?.status === 0) {
      setSubscriptions(result?.data?.subscribersList);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    const getCustomerSubscriptions = async () => {
      setIsLoading(true);
      if (customers.length === 0) {
        const result = await customerManager.getCustomersApi();
        customers = result?.data?.subscribersList;
        const customer = customerManager.getCustomerById(customerId);
        setSelectedCustomer(customer);
      }
      getSubscriptionsApiHandler();
    };
    setSelectedCustomer(customerManager.getCustomerById(customerId));
    getCustomerSubscriptions();
    setSelectedFilter(OPTIONS && OPTIONS[0]);
  }, [customerId]);

  const backHandler = () => {
    navigate("/customers");
  };

  const openSubscriptionModalHandler = (sub, isEditing) => {
    setIsEditing(isEditing);
    setSelectedSubscription(sub);
    setIsDeviveModalOpen(true);
  };

  const resendPasswordHandler = async (subscription) => {
    const result = await subscriptionManager.resendSubscriptionPasswordApi(
      subscription ? subscription.id : selectedSubscription?.id
    );
    if (result.status === 0) {
      setIsDeviveModalOpen(false);
      setMessageText(
        t("toast_resend_password_success", {
          mac: subscription ? subscription.macAddress : selectedSubscription?.macAddress,
        })
      );
      setShowToast(true);
    }
  };

  const unlinkSubscriptionHandler = async () => {
    const result = await subscriptionManager.unlinkSubscriptionApi(
      selectedSubscription?.id
    );
    if (result.status === 0) {
      setIsDeviveModalOpen(false);
      setMessageText(
        t("toast_unlink_subscription", {
          mac: selectedSubscription?.macAddress,
        })
      );
      setShowToast(true);
    }
    setIsLoading(true);
    getSubscriptionsApiHandler();
  };

  useEffect(() => {
    if (selectedFilter) {
      const filteredSubs = subscriptionManager.getFilteredData(selectedFilter?.id);
      setSubscriptions(
        searchInputValue
          ? subscriptionManager.searchLocally(searchInputValue, filteredSubs)
          : filteredSubs
      );
    }
    // eslint-disable-next-line
  }, [selectedFilter]);

  useEffect(() => {
    if (!searchInputValue) {
      setSubscriptions(subscriptionManager.getFilteredData(selectedFilter?.id));
      return;
    }
    const timeoutId = setTimeout(() => {
      setSubscriptions(
        subscriptionManager.searchLocally(
          searchInputValue,
          subscriptionManager.getFilteredData(selectedFilter?.id)
        )
      );
    }, 500);

    return () => clearTimeout(timeoutId);
    // eslint-disable-next-line
  }, [searchInputValue]);

  return (
    <>
      <header className={classes.header}>
        <div className={classes.title}>
          <span onClick={backHandler}>
            {t("customers")}
            <span className={classes.char}>{"<"}</span>
          </span>
          <h2>{t("company")}</h2>
        </div>
        <div className={classes.row}>
          <CustomSelectInput
            className={classes.customSelect}
            optionValueField={"customerName"}
            options={customers}
            value={selectedCustomer}
            onChange={(e) => navigate(`../customers/${e.target.value}`)}
          />
          <div className={classes.info}>
            <span>
              {t("subscriptions") +
                ": " +
                headerSubscriptionData?.active +
                " / " +
                headerSubscriptionData?.total}
            </span>
            <div className={classes.verticalLine} />
            <div className={classes.offersInfo}>
              <span>
                {t("basic_offers") + ` (${headerSubscriptionData?.basic})`}
              </span>
              <Circle className={classes.bullet} color="var(--neutral-white)" />
              <span>
                {t("advance_offers") + ` (${headerSubscriptionData?.advanced})`}
              </span>
              <Circle className={classes.bullet} color="var(--neutral-white)" />
              <span>
                {t("premium_offers") + ` (${headerSubscriptionData?.premium})`}
              </span>
            </div>
          </div>
        </div>
      </header>
      {isLoading ? (
        <Loader />
      ) : (
        <div className={classes.main}>
          <SearchSortView
            hasSort={true}
            className={classes.searchSortView}
            expButtonClassName={classes.expButton}
            searchInputClassName={classes.searchInput}
            options={OPTIONS}
            selectedSortType={selectedFilter}
            onSelectSortType={setSelectedFilter}
            searchInputValue={searchInputValue}
            searchInputPlaceholder={t("searchSubscription")}
            onClearSearchIput={() => setSearchInputValue("")}
            onChangeSearchInput={(value) => setSearchInputValue(value)}
          />
          <ul className={classes.list}>
            {subscriptions?.map((subscription) => (
              <SubscriptionCard
                onResendPassword={() => resendPasswordHandler(subscription)}
                key={subscription?.id}
                subscription={subscription}
                onEdit={() => openSubscriptionModalHandler(subscription, true)}
                canEdit={canEdit}
              />
            ))}
          </ul>
        </div>
      )}
      {isDeviveModalOpen && (
        <SubscriptionModal
          isModalOpen={isDeviveModalOpen}
          onClose={() => setIsDeviveModalOpen(false)}
          isEditing={isEditing}
          subscription={selectedSubscription}
          onResendPassword={() => resendPasswordHandler()}
          onUnlinkSubscription={unlinkSubscriptionHandler}
        />
      )}
      {showToast && (
        <Toast
          isDisplayed={showToast}
          type="info"
          messageText={messageText}
          onDisplay={(isDisplayed) => setShowToast(isDisplayed)}
        />
      )}
    </>
  );
}

export default Subscriptions;
