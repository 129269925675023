import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { t } from "i18next";

import classes from "./LiveTV.module.scss";
import MenuCardLayout from "../../../../components/menuCardLayout/MenuCardLayout";
import SearchSortView from "../../../../components/ui/searchSort/searchSortView/SearchSortView";
import CategoryItem from "../../../../components/menuItems/categoryItem/CategoryItem";
import NoItemsView from "../../../../components/ui/noItemsView/NoItemsView";
import SortHelper from "../../../../utils/SortHelper";
import useFetchData from "../../../../hooks/useFetchData";
import channelManager from "../../../../backend/managers/ChannelManager";
import ChannelModal from "../../../../components/modals/channelModal/ChannelModal";
import Loader from "../../../../components/ui/loader/Loader";
import ButtonIcon from "../../../../components/ui/buttonIcon/ButtonIcon";
import LinkContentsModal from "../../../../components/modals/linkContentsModal/LinkContentsModal";
import ConfirmationModal from "../../../../components/modals/confirmationModal/ConfirmationModal";
import ContentHelper from "../../../../utils/ContentHelper";
import Toast from "../../../../components/toast/Toast";
import ThemeModal from "../../../../components/modals/themeModal/ThemeModal";
import { errorActions } from "../../../../store/reducers/Error-slice";
import ContentCard from "../../../../components/contentCards/contentCard/ContentCard";

function LiveTV() {
  const SORT_OPTIONS = [...SortHelper.getContentSortOptions(), { id: "higher_rank" }];

  const [categories, setCategories] = useState([]);
  const [isLoading, setIsLoading] = useState();
  const [selectedCategory, setSelectedCategory] = useState();
  const [selectedChannel, setSelectedChannel] = useState();
  const [selectedSortType, setSelectedSortType] = useState(SORT_OPTIONS[2]);
  const [searchInputValue, setSearchInputValue] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [isChannelModalOpen, setIsChannelModalOpen] = useState(false);
  const [isLoadingModal, setIsLoadingModal] = useState(false);
  const [isLodingCard, setIsLodingCard] = useState(false)
  const [isCategoryModalOpen, setIsCategoryModalOpen] = useState(false);
  const [isLinkContentsModalOpen, setIsLinkContentsModalOpen] = useState(false);
  const [isConfModalOpen, setIsConfModalOpen] = useState(false);
  const [toast, setToast] = useState();

  const dispatch = useDispatch();

  const {
    isLoading: loading,
    data,
    onRefresh,
  } = useFetchData(channelManager.getChannelsApi);

  useEffect(() => {
    setIsLoading(loading);
    setSelectedSortType(SORT_OPTIONS[2]);
    setCategories(data)
    setSelectedCategory((prevState) => {
      if (data?.length > 0) {
        data[0].channels = SortHelper.arrangeData(
          data[0]?.channels,
          true,
          'channelRank',
        );
      }
      return data[0];
    })

    // eslint-disable-next-line
  }, [data]);

  useEffect(() => {
    if (selectedCategory) {
      setSelectedCategory((prevState) => {
        let sortedChannels = []
        selectedSortType?.id === 'higher_rank' ?
          sortedChannels =
          SortHelper.arrangeData(prevState?.channels, true, (selectedCategory?.id === 'display_all' ? 'channelRank' : 'orderInLiveTvCategory'))
          : sortedChannels = SortHelper.sortData(
            prevState?.channels || [],
            selectedSortType?.id
          )

        return { ...prevState, channels: sortedChannels };
      });
    }
    // eslint-disable-next-line
  }, [selectedSortType]);


  const openChannelModalHandler = async (channel, isEditingValue) => {
    setIsChannelModalOpen(true);
    setIsEditing(isEditingValue);
    setSelectedChannel(channel);
  };

  const menuButtons = [
    {
      id: "modify",
      imagePath: "icons/modify",
      activeImagePath: "icons/modify-active",
      disabled: !selectedCategory || selectedCategory?.name === "Uncategorized",
      action: () => {
        setIsCategoryModalOpen(true);
        setIsEditing(true);
      },
    },
    {
      id: "delete",
      imagePath: "icons/delete",
      activeImagePath: "icons/delete-active",
      disabled: !selectedCategory || selectedCategory?.name === "Uncategorized",
      action: () => setIsConfModalOpen(true),
    },
    {
      id: "add",
      imagePath: "icons/add",
      activeImagePath: "icons/add-active",
      action: () => {
        setIsCategoryModalOpen(true);
      },
    },
  ];

  const submitCategoryHandler = async (names, icon, rank, id) => {
    setIsLoadingModal(true);
    let result;
    if (isEditing) {
      result = await channelManager.updateCategoryApi(names, icon, rank, id);
    } else {
      result = await channelManager.createCategoryApi(names, icon, rank);
    }
    if (result?.status === 0) {
      setToast({
        messageText: t(
          isEditing
            ? "toast_edit_category_success"
            : "toast_create_category_success",
          { name: names?.en }
        ),
        type: "info-green",
        isDisplayed: true,
      });
      onRefresh();
      setIsCategoryModalOpen(false);
    } else {
      dispatch(errorActions.setErrorMessage(result?.message || result));
    }
    setIsEditing(false);
    setIsLoadingModal(false);
  };

  const submitLinkContentsHandler = async (
    contentIdsTobeAdded,
    contentIdsTobeRemoved,
    selectedItems
  ) => {
    setIsLoadingModal(true);
    if (contentIdsTobeAdded?.length > 0) {
      const result1 = await channelManager.attachContentsToCatApi({
        catId: selectedCategory?.id,
        liveTvIds: contentIdsTobeAdded,
      });
      if (result1?.status !== 0) {
        dispatch(errorActions.setErrorMessage(result1?.message || result1));
        setIsLoadingModal(false);
        return;
      }
    }
    if (contentIdsTobeRemoved?.length > 0) {
      const result2 = await channelManager.detachContentsFromCatApi({
        liveTvIds: contentIdsTobeRemoved,
        catId: selectedCategory?.id,
      });
      if (result2?.status !== 0) {
        dispatch(errorActions.setErrorMessage(result2?.message || result2));
        setIsLoadingModal(false);
        return;
      }
    }
    if (
      contentIdsTobeAdded?.length === 0 &&
      contentIdsTobeRemoved?.length === 0
    ) {
      setIsLoadingModal(false);
      setIsLinkContentsModalOpen(false);
      return;
    }
    setIsLoadingModal(false);
    setIsLinkContentsModalOpen(false);
    setSelectedCategory((prevState) => {
      if (prevState) {
        prevState.channels = SortHelper.arrangeData(
          selectedItems,
          true,
          prevState.id === 'display_all' ? 'channelRank' : 'orderInLiveTvCategory'
        );
        return prevState;
      }
    });
    // onRefresh();
  };

  const deleteCategoryHandler = async () => {
    setIsLoadingModal(true);
    const result = await channelManager.deleteCategoryApi({
      catId: selectedCategory?.id,
    });
    if (result?.status === 0) {
      setToast({
        messageText: t("toast_delete_category_success"),
        type: "warning",
        isDisplayed: true,
        catId: selectedCategory?.id,
      });
      onRefresh();
      setSelectedCategory(data && data[0]);
    } else {
      dispatch(errorActions.setErrorMessage(result?.message || result));
    }
    setIsConfModalOpen(false);
    setIsLoadingModal(false);
  };

  useEffect(() => {
    if (!searchInputValue) {
      setCategories(data);
      setSelectedCategory((prevState) => {
        const cat = channelManager.getLocalCategoryById(prevState?.id);
        if (cat && cat?.channels)
          cat.channels = SortHelper.sortData(
            cat.channels,
            selectedSortType?.id
          );
        return cat;
      });
      return;
    }
    const timeoutId = setTimeout(() => {
      setCategories(channelManager.searchLocally(searchInputValue));
      setSelectedCategory((prevState) => {
        const updatedCategory = channelManager.getLocalSearchedCategoryById(
          prevState.id
        );
        updatedCategory.channels = SortHelper.sortData(
          updatedCategory.channels,
          selectedSortType?.id
        );
        return updatedCategory;
      });
    }, 500);

    return () => clearTimeout(timeoutId);
    // eslint-disable-next-line
  }, [searchInputValue]);

  const undoDeleteHandler = async () => {
    const result = await channelManager.restoreCategoryApi({
      catId: toast?.catId,
    });
    if (result?.status === 0) {
      onRefresh();
    } else {
      dispatch(errorActions.setErrorMessage(result?.message || result));
    }
  };

  const handleEditRank = async (e, rank, id, categoryId) => {

    const rankNumber = Number(rank)
    //helperFunction to set state categories with new values //  
    const setCategoriesNewDataAndSetInSelected = (fieldTarget) => {
      setSelectedSortType(SORT_OPTIONS[2]); // if happen any change in ranking return sort type to By Rank 
      setCategories((prev) => { // modiy the rank in the main list // to make sure that updated when naviagte between the categories//
        const newList = prev.find((ele) => ele.id === categoryId).channels.map((ele) => { if (ele.id === id) { return { ...ele, [fieldTarget]: rankNumber } } else return ele })

        const sortedCategory = prev.map((ele) => {
          if (ele.id === categoryId) {
            const sortedChannels = SortHelper.arrangeData(newList, true, fieldTarget);
            return { ...ele, channels: sortedChannels };
          } else {
            return ele
          }
        })
        setSelectedCategory(() => sortedCategory.find((ele) => ele.id === categoryId))
        return sortedCategory
      })
    }


    // onChange rank and click enter 
    setIsLodingCard(true)
    if (e.key === "Enter") {

      if (rank === '' || rank === 'e') {
        setIsLodingCard(false)
        dispatch(errorActions.setErrorMessage('Missing mandatory request parameter'));
        return
      }

      if (selectedCategory?.id === 'display_all') {
        const result = await channelManager.updateRankGlobal({
          liveTvId: id,
          rank: rankNumber,
        });
        if (result?.status === 0) {
          setCategoriesNewDataAndSetInSelected('channelRank')
        } else {
          dispatch(errorActions.setErrorMessage(result?.message || result));
        }
      } else {
        const result = await channelManager.updateRankCategory({
          catId: selectedCategory?.id,
          liveTvId: id,
          rank: rankNumber
        });
        if (result?.status === 0) {
          setCategoriesNewDataAndSetInSelected('orderInLiveTvCategory')
        } else {
          dispatch(errorActions.setErrorMessage(result?.message || result));
        }
      }
    }
    setIsLodingCard(false)
  }



  return loading ? (
    <Loader />
  ) : (
    <>
      <MenuCardLayout
        pageTitle={t("liveTv")}
        displayAllNumber={
          categories && categories.length > 0 && categories[0]?.channels?.length
        }
        isDisplayAllSelected={selectedCategory?.id === "display_all"}
        onSelectDisplayAll={() => {
          const selectedCat = {
            ...data[0], channels: SortHelper.arrangeData(
              data[0].channels,
              true,
              'channelRank'
            )
          }
          setSelectedSortType(SORT_OPTIONS[2])
          setSelectedCategory(selectedCat);
        }}
        buttons={menuButtons}
        menuItemsListClassName={classes.menuItemsList}
      >
        <div className={classes.headerContainer}>
          <SearchSortView
            hasSort={true}
            options={SORT_OPTIONS}
            selectedSortType={selectedSortType}
            onSelectSortType={setSelectedSortType}
            searchInputValue={searchInputValue}
            searchInputPlaceholder={t("searchChannel")}
            onClearSearchIput={() => setSearchInputValue("")}
            onChangeSearchInput={(value) => setSearchInputValue(value)}
            expButtonClassName={classes.sortBtn}
          />
          <ButtonIcon
            imagePath={"buttons/add"}
            activeImagePath={"buttons/add-active"}
            disabledImagePath={"buttons/add-purple-rounded-disabled"}
            className={classes.btn}
            isDisabled={
              selectedCategory?.id === "display_all" ||
              selectedCategory?.name === "Uncategorized"
            }
            onClick={() => {
              if (
                selectedCategory?.id !== "display_all" &&
                selectedCategory?.name !== "Uncategorized"
              ) {
                setSearchInputValue("");
                setIsLinkContentsModalOpen(true);
              }
            }}
          />
        </div>
        {categories.map((category) => {
          return (
            category.id !== "display_all" && (
              <CategoryItem
                key={category?.id}
                id={category?.id}
                name={
                  category?.name === "Uncategorized"
                    ? category?.name
                    : ContentHelper.getNameByLang(category)
                }
                icon={category?.iconUrl}
                number={category?.channels?.length}
                onClick={() => {
                  category.channels = SortHelper.arrangeData(
                    category?.channels,
                    true,
                    'orderInLiveTvCategory'
                  );
                  setSelectedCategory(category);
                }}
                selectedCategoryId={selectedCategory?.id}
              />
            )
          );
        })}
        {selectedCategory?.channels && selectedCategory?.channels.length > 0 ? (
          <ul className={classes.list}>
            {selectedCategory?.channels?.map((channel) => (
              <ContentCard key={channel?.id}
                isShowView={true}
                onClickView={() => openChannelModalHandler(channel, true)}
                content={channel} className={classes.channelCard}
                type={selectedCategory.id === 'display_all' ? 'all_channels' : (selectedCategory.id === 0 ? false : 'channels')} // check if uncategorized category to remove the input
                isModalLoading={isLodingCard}
                onEditContentRank={(e, rank, id) => handleEditRank(e, rank, id, selectedCategory?.id)}
              />
            ))}
          </ul>
        ) : (
          !isLoading && (
            <NoItemsView text={t("noItemsRow")} showCreateText={true} />
          )
        )}
      </MenuCardLayout >
      {isChannelModalOpen && (
        <ChannelModal
          isModalOpen={isChannelModalOpen}
          onClose={() => {
            setIsChannelModalOpen(false);
            setIsEditing(false);
          }}
          channel={selectedChannel}
          isEditing={isEditing}
        />
      )
      }
      {
        isCategoryModalOpen && (
          <ThemeModal
            isModalOpen={isCategoryModalOpen}
            onClose={() => {
              setIsCategoryModalOpen(false);
              setIsEditing(false);
            }}
            category={
              isEditing ? channelManager.getCatById(selectedCategory?.id) : null
            }
            isLoadingModal={isLoadingModal}
            onSubmit={submitCategoryHandler}
            hasIcon={true}
          />
        )
      }
      {
        isConfModalOpen && (
          <ConfirmationModal
            isModalOpen={isConfModalOpen}
            onClose={() => setIsConfModalOpen(false)}
            onConfirm={deleteCategoryHandler}
            text={t("delete_type", {
              type: t("cat"),
            })}
            description={t("delete_type_conf_message", {
              type: t("cat"),
            })}
            name={ContentHelper.getNameByLang(selectedCategory)}
            btnConfirmTitle={t("yes")}
            btnCancelTitle={t("cancel")}
            isLoading={isLoadingModal}
          />
        )
      }
      {
        isLinkContentsModalOpen && (
          <LinkContentsModal
            isModalOpen={isLinkContentsModalOpen}
            onClose={() => setIsLinkContentsModalOpen(false)}
            isModalLoading={isLoadingModal}
            typeData={selectedCategory}
            onSubmit={submitLinkContentsHandler}
            title={t("add_contents_to_type", {
              type: ContentHelper.getNameByLang(selectedCategory),
            })}
            api={channelManager.getChannelsListApi}
            getOriginalDataSource={channelManager.getOriginalChannelsList}
            initialContents={selectedCategory?.channels}
          />
        )
      }
      {
        toast?.isDisplayed && (
          <Toast
            messageText={toast?.messageText}
            type={toast?.type}
            isDisplayed={toast?.isDisplayed}
            onDisplay={(isDisplayed) =>
              setToast((prevState) => {
                return { ...prevState, isDisplayed: isDisplayed };
              })
            }
            onUndo={toast?.type === "warning" ? undoDeleteHandler : null}
          />
        )
      }
    </>
  );
}

export default LiveTV;
