import { useState } from "react";
import { t } from "i18next";

import classes from "./SubscriptionCard.module.scss";
import backdropStyle from "../../../styles/Backdrop.module.scss";
import Button from "../../ui/button/Button";
import Card from "../../ui/card/Card";
import subscriptionManager from "../../../backend/managers/SubscriptionManager";
import ButtonIcon from "../../ui/buttonIcon/ButtonIcon";

function SubscriptionCard({ subscription, onEdit, canEdit, onResendPassword }) {
  const dataSource = subscriptionManager.prepareData(subscription);

  const [showTooltip, setShowTooltip] = useState(false);



  return (
    <>
      <Card className={classes.card}>
        {subscription?.macAddress ? (
          <>
            {dataSource?.map((row) => (
              <div
                key={row?.label}
                className={`${classes.row} ${row?.bold && classes.bold}`}
              >
                <label>{t(row?.label)}</label>
                <span>{": " + row?.value || t("null")}</span>
              </div>
            ))}
            {canEdit && (
              <div className={classes.actionContainer}>
                <Button
                  title={t("edit")}
                  className={classes.btn}
                  onClick={onEdit}
                />
              </div>
            )}
          </>
        ) : (
          <>
            <img
              alt={t("info")}
              src={require("../../../assets/img/icons/info.png")}
              className={classes.infoIcon}
              onClick={() => setShowTooltip(true)}
            />
            <div className={classes.unlinkContainer}>
              <p>{subscription?.offer}</p>
              <span className={classes.pending}>{t("pending_subscription")}</span>
              <span className={classes.email}>Email : {subscription.email}</span>
              <img
                src={require("../../../assets/img/icons/unlinked.png")}
                alt={t("unlinked")}
              />
            </div>
            <ButtonIcon
              imagePath={"buttons/submit"}
              className={classes.btnSubmit}
              onClick={onResendPassword}
              isSvg={true}
            />
            {showTooltip && (
              <>
                <div
                  className={backdropStyle.backdrop}
                  onClick={() => setShowTooltip(false)}
                />
                <div className={classes.tooltip}>
                  <span className={classes.title}>{t("tool_tips") + " :"}</span>
                  <span>{t("unlinked_tooltip_message")}</span>
                </div>
              </>
            )}
          </>
        )}
      </Card>
    </>
  );
}

export default SubscriptionCard;
